<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="tabNav">
      <div v-for="(item, index) in itemize" :key="index" class="navItem" @click="navChange(index)">
        <div :class="navIndex == index ? 'name navActive' : 'name'"> {{ item.name }} </div>
        <div class="xian" v-if="index < itemize.length - 1"></div>
      </div>
    </div>
    <div class="outer">
      <div class="library">
        <div class="libraryItem" v-for="(item, index) in libraryList" :key="index" @click="downLoad(item)">
          <div class="xian"></div>
          <div class="itemCont">
            <img src="@/assets/img/icon.png" alt="" class="icon" />
            <div class="cont">
              <div class="title">{{ item.title }}</div>
              <div class="tagName">
                <div> {{ $t("sciencePopularization.library.L_tipOne") }}{{ item.name }} </div>
                <div> {{ $t("sciencePopularization.library.L_tipTwo") }}{{ item.book_tag_name }} </div>
              </div>
              <div class="down">
                <div>{{ $t("sciencePopularization.library.L_download") }}</div>
                <img src="@/assets/img/downLoad.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="paging">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="currentPage" :page-size="9" layout="total,prev,pager,next,jumper" :total="count"
          prev-text="上一页" next-text="下一页">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：行业服务-行业科普-图书馆
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-17 14:56
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "行业服务",
          title_en: "Industry service",
          path: "industryService",
        },
        {
          title_zh: "行业科普",
          title_en: "Industry science popularization",
          path: "sciencePopularization",
        },
        {
          title_zh: "图书馆",
          title_en: "library",
          path: "",
        },
      ],
      itemize: [],
      navIndex: 0,
      currentPage: 1,
      libraryList: [],
      count: 0,
    };
  },
  mounted() {
    this.getlibraryClass();
  },
  methods: {
    /**
     * 头部切换
     * 刘嘉鑫
     * 2022-8-17
     */
    navChange(index) {
      this.navIndex = index;
      this.getLibraryList();
    },

    /**
     * 分页
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.getLibraryList();
    },

    /**
     * 图书馆分类
     * 刘嘉鑫
     * 2022-8-19
     */
    getlibraryClass() {
      console.log(localStorage.getItem("token"));
      let that = this
      this.$request({
        url: "/Index/bookType",
        data: { token: localStorage.getItem("token") },
      }).then((res) => {
        this.$set(this, 'itemize', res.book_type)
        console.log(this.itemize)
        this.getLibraryList();
      })
    },

    /**
     * 图书馆列表
     * 刘嘉鑫
     * 2022-8-19
     */
    getLibraryList() {
      console.log(localStorage.getItem("token"));
      let that = this
      this.$request({
        url: "/index/bookList",
        data: {
          page: this.currentPage,
          token: localStorage.getItem("token"),
          type_id: this.itemize[this.navIndex].id,
        },
      }).then((res) => {
        console.log(res)
        this.libraryList = res.book_list;
        this.count = res.book_count;
        this.currentPage = ++this.currentPage
      })

    },

    /**
     * 下载文档
     * 刘嘉鑫
     * 2022-8-19
     */
    downLoad(item) {
      let a = document.createElement("a");
      a.href = item.file;
      a.click();
      // this.downloadPicture(item.file)
    },

    downloadPicture(imgSrc, name) {
      const image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.src = imgSrc;
      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        const context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.download = name || "photo";
          a.href = url;
          a.click();
          a.remove();
          URL.revokeObjectURL(url);
        });
      };
    },
  },
};
</script>
<style lang='scss' scoped>
.tabNav {
  padding: 0 220px;
  background: #ffffff;
  display: flex;
  align-items: center;

  .navItem {
    padding: 25px 0 0;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
  }

  .name {
    padding: 0 18px;
    border-bottom: 4px solid transparent;
    font-size: 18px;
    font-family: FZFengYaSongS-GB;
    font-weight: bold;
    color: #444444;
    padding-bottom: 21px;
    margin-left: 42px;
  }

  .xian {
    width: 2px;
    height: 15px;
    background: #cccccc;
    margin: 5px 0 0 42px;
  }

  .navActive {
    color: #1a2a60;
    border-bottom: 4px solid #1a2a60;
  }
}

.outer {
  background: #f6f6f6;
  padding: 80px 310px 120px;
}

.library {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 40px;

  .libraryItem {
    width: 30%;
    height: 157px;
    margin: 0 64px 60px 0;
    cursor: pointer;

    display: flex;

    .xian {
      width: 8px;
      height: 100%;
      background: linear-gradient(135deg, transparent 4px, #1a2a60 0) top left,
        linear-gradient(45deg, transparent 4px, #1a2a60 0) bottom left;
      background-size: 100% 90%;
      background-repeat: no-repeat;
    }

    .itemCont {
      display: flex;
      align-items: flex-start;
      background: #ffffff;
      padding: 28px 40px 20px 30px;
      width: 100%;

      //   height: 100%;
      .icon {
        width: 15px;
        height: 18px;
        // background: #1a2a60;
        margin: 4px 14px 0 0;
      }

      >.cont {
        display: flex;
        flex-direction: column;
        height: 100%;

        .title {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #222222;
        }

        .tagName {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #5e6374;
          margin-top: 10px;
        }

        .down {
          display: flex;
          align-items: center;
          margin-top: auto;

          >div {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #1a2a60;
          }

          >img {
            width: 14px;
            height: 14px;
            margin-left: 8px;
          }
        }
      }
    }
  }

  .libraryItem:nth-child(3n) {
    margin-right: 0 !important;
  }
}
</style>